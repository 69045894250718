.confirm-modal-wrapper {
  .modal-content {
    border-radius: 10px;
  }
  .modal-header {
    font-family: "GT America Bold";
  }
  .modal-body {
    font-family: "GT America Medium";
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .confirm-modal-image {
    width: 223px;
    height: 223px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .confirm-modal-title {
    margin-top: 24px;
    margin-bottom: 10px;
    font-family: "GT America Bold";
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #19233c;
  }
  .confirm-modal-desc {
    font-family: "GT America Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #19233c;
  }
  .confirm-modal-actions {
    display: flex;
    justify-items: center;
    margin-top: 24px;
    column-gap: 10px;
  }
}

.modal-invite-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e7eaf1;
  border-radius: 8px;
  padding: 4px 10px;
  min-width: 440px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    color: #19233c;
  }
}

.modal-invite-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  margin: 19px 0;
  margin-top: 0px;
  text-align: center;

  color: #19233c;
}

.post-match-modal {
  // max-width: 70%;
  max-width: 1263px !important;
  .modal-content {
    width: 1263px;
  }
  .arena-img {
    position: absolute;
    left: 5px;
    width: 316px;
    height: 133px;
    img {
      width: 100%;
      height: auto;
    }
  }
  border: none;
  .post-match-modal-body {
    border: none;
    background-color: rgba(49, 30, 134, 0.7);
    backdrop-filter: blur(4px);
    margin-top: 30px;
    border-radius: 5px 5px 0 0;
  }
  .modal-content {
    // background-color: rgba(49, 30, 134, 0.7);
    // backdrop-filter: blur(4px);
    border: none;
    background-color: transparent;
  }
  .post-match-modal-header {
    color: #fff;
    font-family: "GT America Bold";
    font-size: 58px !important;
    width: 100%;
    letter-spacing: 0.02em;
    border: none;
    background-color: rgba(49, 30, 134, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 5px;
    height: 175px;
    h5 {
      font-size: 58px !important;
      width: 100%;
    }
    .modal-header-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dummy-bot-img {
      position: absolute;
      right: 45px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .modal-footer {
    border: none;
    background-color: rgba(49, 30, 134, 0.7);
    backdrop-filter: blur(4px);
    justify-content: center;
    column-gap: 18px;
  }

  .winnings-description {
    font-size: 14px;
    font-family: "GT America Regular";
    text-align: center;
    margin-bottom: -14px;
    letter-spacing: normal;
  }

  .post-match-stats-title {
    color: #bcb9fe;
    font-family: "GT America Bold";
    font-size: 28px !important;
    line-height: 30px;
    text-align: center;
  }
  .post-match-stats-table {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .post-match-table-header {
    display: flex;
    justify-content: space-between;
    color: #311e86;
    font-family: "GT America Bold";
    font-size: 24px;
    line-height: 30px;
    flex-grow: 1;
  }
  .yellow-grad {
    padding: 10px 15px;
    background: linear-gradient(
      270deg,
      rgba(49, 30, 134, 0) -0.08%,
      #fad42f 84.36%
    );
    border-radius: 12px 0px 0px 0px;
    flex-grow: 1;
  }

  .blue-grad {
    padding: 10px 15px;
    background: linear-gradient(
      270deg,
      #a592ee 26.5%,
      rgba(49, 30, 134, 0) 100%
    );
    border-radius: 0px 12px 0px 0px;
    flex-grow: 1;
    text-align: right;
  }

  .round-stats {
    background: #311e86;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 15px 20px;
  }

  .stats-value {
    font-family: "GT America Medium";
    display: flex;
    font-size: 24px;
    line-height: 20px;
    column-gap: 65px;
    width: 388.67px;
    /* identical to box height, or 83% */

    color: #ffffff;
    .health-value {
      width: 53px;
    }
  }

  .round-stats-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reverse-flex {
    flex-direction: row-reverse;
  }

  .rounds-value {
    font-family: "GT America Bold";
    // display: flex;
    font-size: 24px;
    color: #ffffff;
  }
}

.booker-modal {
  .modal-content {
    border: none;
    background-color: rgba(49, 30, 134, 0.7);
    backdrop-filter: blur(4px);
    color: #fff;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
  }
  .booker-message-heading {
    font-family: "GT America Bold";
    font-size: 24px;
  }

  .booker-modal-message {
    font-family: "GT America Medium";
    text-align: center;
    font-size: 20px;
  }
  .modal-footer {
    border: none;
    justify-content: center;
    column-gap: 18px;
  }
  .booker-modal-btn-content {
    display: flex;
    align-items: center;
    column-gap: 12px;
    img {
      width: 25px;
      height: auto;
    }
  }
}

.loadout-modal {
  .modal-content {
    padding: 35px;
  }
  .modal-header {
    padding: 0 0 18px 0;
    justify-content: center;
    font-family: "GT America Bold";
    font-weight: 700;
    font-size: 18px;
    color: #19233c;
  }
  .loadout-modal-input-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .input-label {
      font-family: "GT America Bold";
      font-size: 18px;
      line-height: 19px;
      color: #19233c;
      font-weight: 700;
    }
    input {
      box-shadow: none !important;
      background: #ffffff !important;
      border: 1px solid #aeb8d0 !important;
      box-sizing: border-box;
      border-radius: 8px;
      font-family: "GT America Regular";
      font-size: 18px;
      line-height: 30px;
      font-weight: 400; // color: #AEB8D0 !important;
    }
  }
  .load-modal-footer {
    justify-content: center;
    border-top: none !important;
  }
}

body.modal-open {
  padding-right: 4px !important;
}
