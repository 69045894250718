.banner-container {
  margin: 0;
  padding: 0;
  background-color: #311e86;
  color: #fff;
  font-family: "GT America Medium";
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: #fff;
    color: #311e86;
    margin-left: 16px;
    border: 1px solid transparent;
    padding: 8px 16px;
  }
  button:hover,
  button:active {
    background-color: #bdb9ff;
    color: #311e86;
  }
}
