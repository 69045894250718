.powerupCardContainer {
  min-height: 196px;
  max-width: 192px;
  margin: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 15px rgba(205, 216, 237, 0.5);

  .powerupContentContainer {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .powerupCardImageContainer {
    max-width: 192px;
    height: 133px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .powerupCardImage {
      height: 133px;
      width: 192px;
      object-fit: contain;
    }
  }
  .powerupTextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .powerupText {
      font-family: "GT America Bold";
      font-size: 18px;
      color: #19233c;
      margin-bottom: 0px;
    }
    .powerupQuantity {
      padding: 1.9px 16px;
      background-color: #bdb9ff;
      border-radius: 5px;
      color: #311e86;
      font-family: "GT America Bold";
      font-size: 18px;
      margin: 0;
    }
  }
}

.selectedPowerupCardContainer {
  border: 2px solid #311e86;
}

.powerupSelectedContainer {
  display: flex;
  margin: 0px 16px;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;

  .quantityValue {
    font-family: "GT America Bold";
    font-size: 15px;
    color: #19233c;
    margin-bottom: 0px;
  }

  .operatorContainer {
    padding: 0px 8px;
    background-color: #f6f9ff;
    border-radius: 5px;
    color: #311e86;
    border-radius: 5px;
    margin-bottom: 0px;
    cursor: pointer;

    p {
      margin: 0;
      margin-top: -5px;
      color: #311e86;
      font-family: "GT America Bold";
      font-size: 19px;
      font-weight: 800;
    }
  }
}
