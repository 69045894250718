.fightPageContainer {
  background-color: #333333;
  height: 100vh;
  width: 100%;
  .canvasContainer {
    height: 100vh;
    position: relative;
  }

  .threeCanvas {
    z-index: 1;
  }
  .backgroundImageContainer {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
  .buttonsContainer {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .headsUpDisplayContainer {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    align-items: center;
    .versusText {
      color: #ffffff;
      font-size: 96px;
      font-family: "Kanit Bold";
      margin: 0 auto;
    }
  }
}

.errorContainer {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  width: 100%;

  .errorImage {
    width: 85px;
    height: 75px;
    margin: 48px 0px;
  }
  .errorTitle {
    font-size: 36px;
    font-family: "GT America Bold";
    color: #ffffff;
    text-align: center;
    width: 80%;
  }
  .errorMessage {
    font-size: 24px;
    font-family: "GT America Regular";
    color: #ffffff;
    text-align: center;
    width: 80%;
  }
}
