.statCardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 5px 8px;
  margin: 8px 0px;

  .statCardIconPlaceholder {
    height: 26px;
    width: 26px;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 15px;
  }
  .statCardLabel {
    margin: 0px;
    font-family: "GT America Medium";
    font-size: 14px;
  }
  .statCardValue {
    margin: 0px;
    font-family: "GT America Medium";
    font-size: 14px;
  }
}

.statCardContainerSm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  width: 65px;

  .statCardIconPlaceholderSm {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
  }
  .statCardLabelSm {
    // text-transform: uppercase;
    margin: 0px;
    font-family: "GT America Medium";
    font-size: 14px;
  }
  .statCardValueSm {
    margin: 0px;
    font-family: "GT America Medium";
    font-size: 14px;
  }
}
