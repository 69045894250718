.cardContainer {
  width: 270px;
  height: 75px;
  border-radius: 16px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .imageAndTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .imageContainer {
      // background-color: #bdb9ff;
      height: 28px;
      width: 33px;

      margin-right: 10px;
      img {
        height: 28px;
        width: 33px;
      }
    }

    .cardTitle {
      size: 16px;
      font-family: "GT America Bold";
      // margin-bottom: 8px;
      margin-bottom: 0px;
    }
    .cardSubtitle {
      font-size: 14px;
      font-family: "GT America Regular";
      margin-bottom: 0px;
      color: #707d9b;
    }
  }

  .cardAddButton {
    background-color: #311e86;
    color: #ffffff;
    padding: 8px 20px;
    border: none;
    font-family: "GT America Bold";
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
  }
  .cardRemoveButton {
    background-color: #311e86;
    color: #ffffff;
    padding: 8px 20px;
    border: none;
    font-family: "GT America Bold";
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    // opacity: 50%;
  }
}

.actionBlankCard {
  border: 2px dashed #ABC2F0;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 15px rgba(205, 216, 237, 0.5));
  border-radius: 16px;
  background-color: transparent;
  width: 270px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GT America Medium";
  color: #4e82eb;
}

.actionCardActive {
  background-color: #abc2f0;
  color: #311e86;
}

.disabledCard {
  // pointer-events: none;
  opacity: 0.8;
  button {
    pointer-events: none;
  }
}
