@font-face {
  font-family: "DM Sans Bold";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Medium";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Regular";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT America Bold";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/GT-America-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "GT America Regular";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/GT-America-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT America Medium";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("./fonts/GT-America-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Kanit Bold";
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("./fonts/Kanit-Bold.ttf") format("truetype");
}
