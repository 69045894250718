.headsUpDisplayContainer {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  // top: 50px;
  width: 100%;
  .versusText {
    color: #00ffff;
    font-size: 96px;
  }
}

.HUDInnerContainer {
  display: flex;
  background: rgba(49, 30, 134, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 50px 0px;
  padding: 30px;
}
.InvertedHUDInnerContainer {
  display: flex;
  flex-direction: row-reverse;
  background: rgba(49, 30, 134, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 0px 50px;
  padding: 30px;
}
.blockbotHUDContainer {
  // background-color: #ffffff;
  // margin-top: 24px;
  width: 610px;
  // padding: 30px;

  .blockbotHUDImageContainer {
    .blockbotHUDImage {
      height: 88px;
      width: 88px;
      border-radius: 10px;
    }
    .invertedBlockbotHUDImage {
      height: 88px;
      width: 88px;
      border-radius: 10px;
      transform: scaleX(-1);
    }
  }

  .HUDDetailsContainer {
    margin-left: 21px;
    margin-right: 21px;
    flex-grow: 1;
    .HUDName {
      font-family: "GT America Bold";
      font-size: 24px;
      color: #ffffff;
    }
  }
}

.straightContainer {
  border-radius: 0px 5px 5px 0px;
}
.invertedContainer {
  border-radius: 5px 0px 0px 5px;
}

.baseStatsContainer {
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  column-gap: 10px;
  .statImageStyles {
    height: 26px;
    width: 31px;
    object-fit: contain;
  }

  .statsWrapper {
    width: 130px;
    padding: 10px 15px;
    font-family: "GT America Bold";
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 19px;
  }
}

.healthBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 16px;
  // background-color: #dbffe5;
  padding: 10px 0px;
  .healthBarTextContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .healthBarLabel {
      margin-bottom: 0px;
      // margin-left: 8px;
      font-family: "GT America Bold";
      color: #ffffff;
    }
  }
  .healthBarValue {
    margin-bottom: 0px;
    font-family: "GT America Bold";
    color: #ffffff;
    font-size: 30px;
    line-height: 19px;
  }
  .statImageStyles {
    height: 26px;
    width: 31px;
    object-fit: contain;
  }
}

.invertedRow {
  flex-direction: row-reverse;
}

.progressBarContainer {
  height: 50px;
  // background-color: red;
  width: 100%;
}

.progress.right .progress-bar {
  float: right;
}

.healthBar {
  height: 2rem !important;
}

.healthBarBg {
  background-color: #CBFA91;
}