.currentRoundTextContainer {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);

  .currentRoundText {
    font-size: 120px;
    color: #ffffff;
  }
}

.lottieAnimationContainer {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  @media(max-width: 1280px) {
    top: 35%;
  }
}
