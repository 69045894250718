@import "../../../pages/Wallet/Wallet.module.scss";

.powerupsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  .depositModalWalletPowerupsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    justify-content: center;
  }

  .customButton {
    @include buttonStyles();
    padding: 13.5px 25px;
  }
}
