@import "./typography.scss";
@import "./page.scss";
@import "./buttons.scss";
@import "./custom-modal.scss";
@import "./cards.scss";
@import "./banner.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.main-container {
  display: flex;
  min-height: calc(100vh - 80px - 72px);
  flex-direction: column;
}

.white-bg {
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.empty-state {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // min-height: 50vh;

  span {
    font-family: "GT America Regular";
    font-size: 24px;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  .sub-text {
    font-family: "GT America Regular";
    font-weight: normal;
    color: grey;
    font-size: 18px;
    margin-top: 5px;
  }
}

.blue-container {
  background-color: #bdb9ff;
  width: 565px;
  height: 393px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin: 30px 0;
}

.default-blue-button {
  font-family: "GT America Regular";
  background-color: #311e86 !important;
  // margin-top: 12px;
  width: 105px;
}

.no-event {
  // pointer-events: none;
  cursor: not-allowed;
  filter: blur(5px);
  filter: brightness(80%);
  -webkit-filter: blur(5px); /*sepia, hue-rotate, invert....*/
  -webkit-filter: brightness(80%);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
  flex-grow: 1;
}

.agi-bck {
  background-color: #fff3c1;
  color: #856e0e;
}

.str-bck {
  background-color: #ffcfc5;
  color: #9a1e04;
}

.def-bck {
  background-color: #bdeeff;
  color: #095975;
}

.scene-loader {
  font-family: "GT America Medium";
  font-size: 18px;
  white-space: nowrap;
  // width: 100%;
  color: #ffffff;
}

.ellipsis-loader {
  display: flex;
  font-family: "GT America Regular";
  font-size: 36px;
  line-height: 30px;
}

.dotOne {
  animation: visibilityOne 2s linear infinite;
}
@keyframes visibilityOne {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotTwo {
  animation: visibilityTwo 2s linear infinite;
}
@keyframes visibilityTwo {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotThree {
  animation: visibilityThree 2s linear infinite;
}
@keyframes visibilityThree {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.terms-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.terms-wrapper {
  width: 900px;
  color: #000;
  font-family: "GT America Medium";
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  .terms-heading {
    font-family: "GT America Bold";
    font-size: 24px;
  }
  .terms-subheading {
    font-family: "GT America Medium";
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 15px;
  }
  .terms-content {
    p {
      margin-bottom: 0.8rem;
    }

    li {
      margin-bottom: 1rem;
    }
    ol {
      margin-bottom: 1rem;
    }
  }
}

.footer-wrap {
  font-family: "GT America Medium";
}
.terms-link {
  right: 5%;
  a {
    text-decoration: none;
  }
}

.footer-socials {
  position: absolute;
  left: 5%;
  display: flex;
  column-gap: 24px;
  svg {
    width: 25px;
    height: auto;
    // color: #311E86;
    cursor: pointer;
  }
  img {
    width: 25px;
    height: auto;
    cursor: pointer;
  }
}

.arrow-btn-styles {
  border: 0px;
  background: transparent;
}

.primary-button {
  &:active,
  &:hover {
    background-color: #bcb9ff !important;
  }
}
