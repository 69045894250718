.wizard-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f9ff;
  padding: 40px 88px 20px;
  flex-grow:1;
}

.wizard-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #19233c;

  .step-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .step-current {
    font-size: 16px;
    line-height: 20px;
    font-family: "GT America Regular";
  }
  .step-desc {
    font-size: 24px;
    line-height: 30px;
    font-family: "GT America Medium";
    font-weight: bold;
  }
}
