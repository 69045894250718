.blockBoutMain {
  background-color: #a5dadf;

  height: 100vh;
  width: 100%;
  .blockBoutHeader {
    height: 158px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 36px;
    }
  }

  .matchComponent {
    justify-content: space-evenly;
  }
}

.spinnerContainer {
  margin: 24px auto;
  height: 48px;
}

.nextButton {
  margin-top: 64px;
  height: 58px;
  width: 185px;
  background-color: #311e86;
  color: #ffffff;
  font-size: 24px;
  font-family: "GT America Bold";
  border: none;
  border-radius: 5px;
}

.selectFightContainer {
  background-color: #bdb9ff;
  width: 100%;
  height: calc(100vh - 80px - 72px);
  display: flex;
  flex-direction: column;
  background-image: url("https://blockbots-s3.s3.ap-southeast-1.amazonaws.com/BG_Game_Screen.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  // background-origin: border-box;
}

.selectFightTypeTitle {
  display: flex;
  justify-content: center;
  margin-top: 90px;
  // margin-bottom: 129px;
  color: #311e86;

  @media (max-height: 899px) {
    margin-top: 30px;
  }
  h1 {
    font-family: "GT America Medium";
    font-weight: bold;
    font-size: 64px;
    margin-bottom: 0;
    line-height: 67px;
  }
}

.fightTypesContainer {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  padding-top: 85px;
  height: 977px;
}
