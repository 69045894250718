.selectedBlockbotTitle {
  // margin-top: 64px;
  margin-bottom: 25px;
  font-family: "GT America Bold";
  font-size: 24px;
  line-height: 30px;
}

.selectedPUContainer {
  margin-top: 35px;
  display: flex;
  column-gap: 45px;
}

.selectPowerupsContainer {
  // margin-top: 48px;
  height: 98vh;
  overflow-y: auto;
  padding-bottom: 20px;
  width: 100%;
}

.ownedPowerupsListInnerContainer {
  display: flex;
  flex-wrap: wrap;
}

.powerupGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 25px;
  padding: 15px;
}

.powerupsFlexContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.wizardNavigationContainer {
  margin: 77px 0px 117px 0px;
  display: flex;
  justify-content: center;
  .secondaryButton {
    padding: 20px 65px;
    font-family: "GT America Bold";
    font-size: 24px;
    border: 1px solid #311e86;
    border-radius: 5px;
    background-color: transparent;
    color: #311e86;
    margin-right: 64px;
  }
  .primaryButton {
    padding: 20px 65px;
    font-family: "GT America Bold";
    font-size: 24px;
    border-radius: 5px;
    background-color: #311e86;
    color: #ffffff;
  }
  .primaryButton:disabled,
  .primaryButton[disabled] {
    background-color: #423775;
    color: #ffffff;
    cursor: not-allowed;
  }
}

.navBtnCont {
  display: flex;
  gap: 15px;
}

.LoadoutButtonsContainer {
  display: flex;
}

.VerticalDiv {
  width: 24.97px;
  height: 0px;
  border: 1px solid #abc2f0;
  transform: rotate(90deg);
  margin-top: 1px;
}
