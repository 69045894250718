.primary-blue-btn {
  background-color: #311e86 !important;
  border-radius: 5px;
  min-width: 107px;
  max-width: 200px;
  height: 52px;
  font-family: "GT America Medium";
  font-size: 16px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  .dropdown-toggle {
    background-color: inherit !important;
    box-shadow: none !important;
  }

  .dropdown-menu {
    width: 236px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 5px;
  }

  .dropdown-item {
    color: #311e86;
    font-family: "GT America Bold";
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 42px;
    display: block;
    // align-items: center;
    column-gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: #9a95ed;
      color: #fff;
    }
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    column-gap: 10px;
    max-width: inherit;
  }
  .dropdown-toggle::after {
    display: none;
  }

  .toggle-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.white-btn,
.outline-btn {
  padding: 10px 20px;
  gap: 8px;

  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(205, 216, 237, 0.5);
  border-radius: 5px;
  border: none;
  color: #311e86;
  font-weight: 700;
  outline: none;

  display: flex;
  align-items: center;
  border: 1px solid transparent;

  p {
    margin: 0;
  }
  &:hover,
  &:focus,
  &:active {
    background: inherit;
    border: 1px solid black;
    color: #000;
    outline: none;
    outline: none !important;
    box-shadow: none !important;
  }
}

.outline-btn {
  background-color: transparent;
  border: 1px solid #311e86;
  padding: 12.5px 20px;
}

.round-btn {
  padding: 0 10px;
  border-radius: 100%;
}

.red-btn {
  padding: 10px 20px;
  gap: 8px;

  background: #e55436;
  box-shadow: 0px 4px 15px rgba(205, 216, 237, 0.5);
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: 700;
  outline: none;

  display: flex;
  align-items: center;
  border: 1px solid transparent;

  p {
    margin: 0;
  }
  &:hover,
  &:focus,
  &:active {
    background: #c0442b;
    border: 1px solid black;
    outline: none;
    outline: none !important;
    box-shadow: none !important;
  }
}

.thin-btn {
  width: 85px;
  height: 32px;
  font-size: 14px;
  line-height: 18px;
}

.btn-selected {
  background-color: #bdb9ff !important;
  color: #311e86 !important;
}

.transparent-btn {
  background-color: transparent !important;
  border: 1px solid #311e86 !important;
  color: #311e86 !important;
  .dropdown-toggle {
    color: #311e86 !important;
  }
}

.light-blue-btn {
  background-color: #bdb9ff !important;
  color: #311e86 !important;
}

.white-brd {
  border-color: #ffffff !important;
  color: #ffffff !important;
}
