.soundConsoleContainer {
  position: absolute;
  right: 0px;
  top: 500px;
  z-index: 100; //TO OVERRIDE BOOTSTRAP POST GAME MODAL Z-INDEX WHICH IS 1060
}

.closeButtonContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  .closeButtonIcon {
    color: #ffffff;
  }
}

.consoleButtonContainer {
  width: 80px;
  height: 80px;
  background-color: #5c48b9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.consoleButtonIcon {
  font-size: 32px;
  color: #ffffff;
}

.expandedConsoleContainer {
  background-color: #5c48b9;
  width: 300px;
  height: 185px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.soundEnableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.soundEnableIcon {
  color: #ffffff;
  font-size: 24px;
}

.soundEnableText {
  font-size: 18px;
  font-family: "GT America Bold";
  color: #ffffff;
}

.sliderContainer {
  margin: 16px 12px;
  width: 100%;
  .sliderText {
    color: #ffffff;
    margin: 0px;
    font-family: "GT America Bold";
    font-size: 12px;
  }
  .sliderInnerContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .sliderLeftIcon {
    color: #ffffff;
    margin-right: 16px;
    font-size: 18px;
  }
  .sliderRightIcon {
    color: #ffffff;
    margin-left: 16px;
    font-size: 18px;
  }

  .slider {
    width: 280px;
  }

  input[type="range"] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    accent-color: #ffffff;
  }
  input[type="range"]:focus {
    outline: none;
    accent-color: #ffffff;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #311e86;
    accent-color: #ffffff;
  }
  input[type="range"]::-webkit-slider-thumb {
    height: 12px;
    width: 8px;
    background: #bdb9ff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #311e86;
    accent-color: #ffffff;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #311e86;
    accent-color: #ffffff;
  }
  input[type="range"]::-moz-range-thumb {
    height: 12px;
    width: 8px;
    background: #bdb9ff;
    cursor: pointer;
    margin-top: -2px;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type="range"]::-ms-thumb {
    height: 12px;
    width: 8px;
    background: #bdb9ff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #311e86;
    accent-color: #ffffff;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #311e86;
    accent-color: #ffffff;
  }
}
