.header {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 450px) {
    height: 100%;
  }

  .headerContainer {
    padding: 0 88px;
    width: 100%;
  }

  .navLink {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .logo {
    border-radius: 45px;
    background-image: url(../../images/Blockbot_Logo.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 110px;
    height: 52px;
    width: 52px;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 450px) {
      width: 50px;
      height: 50px;
      background-size: 250%;
      margin-top: 0;
    }
    // // .circle {
    // //   box-sizing: border-box;
    // //   border-radius: inherit;
    // //   img {
    // //     width: 85%;
    // //     height: auto;
    // //     transform: scaleX(-1);
    // //   }
    // }
  }
  .logoTitle {
    margin: 0;
    margin-left: 24px;
    font-family: "GT America Bold";
    font-size: 24px;
    line-height: 26px;
    color: #19233c;
  }
  .logoSubTitle {
    margin: 0;
    margin-left: 24px;
    font-family: "GT America Regular";
    font-size: 14px;
    line-height: 16px;
    color: #19233c;
  }
  .navContainer {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    align-items: center;
    @media (max-width: 450px) {
      flex-wrap: wrap;
      width: unset;
    }
    .navItem {
      a {
        color: #19233c;
        font-family: "GT America Bold";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;
      }
    }
    div {
      margin-right: 15px;
    }
  }
  .searchContainer {
    width: 276px;
    input[type="text"] {
      border: 1px solid #19233c !important;
      background-color: #003152;
      color: #19233c;
      border-radius: 1px;
      :focus {
        outline: none;
      }
    }
    input::placeholder {
      color: #d2d7dc;
      font-family: "GT America Regular";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
  .socialIcons {
    display: flex;
    width: 10%;
    height: 100%;
    align-self: center;
    @media (max-width: 450px) {
      width: unset;
      justify-content: flex-end;
      padding-top: 5px;
      // flex-direction: column;
    }
    svg {
      color: white;
      width: 26px;
      height: 29px;
      margin-right: 1rem;
      @media (max-width: 450px) {
        width: 20px;
        height: 29px;
        // flex-direction: column;
      }
    }
    img {
      color: white;
      width: 26px;
      height: 29px;
      @media (max-width: 450px) {
        width: 20px;
        height: 29px;
        // flex-direction: column;
      }
    }
  }
}

.hamMenuContainer {
  //display: flex;
  width: 100%;
  justify-content: space-around;
}
.mobileCollapse {
  padding-top: 20px;
}

.mobileNavItem {
  text-align: right;
  margin: 10px;
  font-family: "GT America Medium";
  a {
    color: #333333;
    text-decoration: none;
  }
}

.connectButton {
  color: #fff;
  background-color: #311e86;
  font-size: 16px;
  font-family: "GT America Medium";
  padding: 8px 32px;

  &:hover {
    color: #fff;
    background: #311e86;
  }
  &:focus {
    color: #fff;
    background: #311e86;
  }
  &:disabled {
    color: #fff;
    background: #311e86;
  }
}

.headerUserProfileContainer {
  background-color: #eaeffa;
  width: 172px;
  height: 56px;
  display: flex;
  padding: 10px;
  border-radius: 5px;

  .defaultAvatarContainer {
    background-color: #311e86;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    .defaultAvatarImage {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }
  }

  .headerUserAvatarImageContainer {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    .headerUserAvatarImage {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 20px;
    }
  }
  .headerUserTextContainer {
    margin-left: 10px;
    .headerUsername {
      font-size: 12px;
      font-family: "GT America Bold";
      color: #19233c;
      margin: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .headerEthAddress {
      font-size: 12px;
      font-family: "GT America Regular";
      color: #19233c;
      margin: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.navInternalLink {
  font-family: "GT America Bold";
  cursor: pointer;
}
