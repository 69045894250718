.blockbotCardContainer {
  // margin: 18px 0px;

  width: 320px;

  // min-height: 502px;
  border-radius: 16px;
  // background: #ffffff;
  padding-top: 15px;
  // cursor: pointer;
  .blockbotCardInnerContainer {
    box-shadow: 0px 4px 4px rgb(205 216 237 / 54%);
    // width: 320px;

    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    // height: 588px;
    // border: 5px;
  }
  .blockbotCardName {
    font-family: "GT America Bold";
    margin: 0px;
    text-align: center;
  }

  .blockbotCardImageContainer {
    margin-top: 15px;
    margin-bottom: 30px;
    height: 280px;
    width: 280px;
    border-radius: 16px;
    position: relative;
    img {
      height: 280px;
      width: 280px;
      border-radius: 16px;
    }
  }

  .selectedButton {
    // height: 32px;
    width: 137px;
    background-color: #311e86;
    border-radius: 5px;
    position: absolute;
    left: 71px;
    bottom: 20px;
    p {
      font-size: 14px;
      color: #ffffff;
      font-family: "GT America Bold";
      margin: 0px;
      padding: 7px 39px;
      text-align: center;
    }
  }

  .selectButton {
    // height: 32px;
    width: 137px;
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 71px;
    bottom: 20px;
    text-align: center;
    p {
      font-size: 14px;
      color: #311e86;
      font-family: "GT America Bold";
      margin: 0px;
      padding: 7px 39px;
      // text-align: center;
    }
  }

  .clanContainer {
    display: flex;
    justify-content: space-between;
    font-family: "GT America Regular";
    .selectedClanLabel {
      font-size: 10px;
      color: #e8ebf3;
      line-height: 19px;
    }
    .clanLabel {
      font-size: 10px;
      line-height: 19px;
      color: #19233c;
    }

    .selectedClanValue {
      font-size: 18px;
      line-height: 19px;
      color: #e8ebf3;
      font-weight: bold;
    }
    .clanValue {
      font-size: 18px;
      line-height: 19px;
      color: #19233c;
      font-weight: bold;
    }
  }

  .statsToggleContainer {
    border: 1px solid #cccccc;
    padding: 7px 20px;
    border-radius: 5px;

    .statsToggleTextContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .selectedStatsToggleTextContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .selectedIcon {
        color: #e8ebf3;
      }
    }

    .statsToggleLabel {
      margin: 0px;
      font-size: 10px;
      line-height: 19px;
      font-family: "GT America Bold";
    }

    .selectedStatsToggleLabel {
      margin: 0px;
      font-size: 10px;
      line-height: 19px;
      font-family: "GT America Bold";
      color: #e8ebf3;
    }
  }
}
