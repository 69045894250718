.profilePageContainer {
  background-color: #f6f9ff;
  width: 100%;
  min-height: calc(100vh - 90px);
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profilePageTitle {
      padding-top: 48px;
      font-family: "GT America Bold";
      font-size: 24px;
    }
  }

  .profilePageTabTitlesContainer {
    display: flex;
    background-color: #ffffff;
    margin-top: 65px;

    .selectedProfilePageTab {
      padding: 15px 34px;
      font-family: "GT America Bold";
      font-size: 24px;
      text-decoration: underline;
      text-decoration-color: #311e86;
      text-underline-offset: 8px;
      cursor: pointer;
    }
    .profilePageTab {
      padding: 15px 34px;
      font-family: "GT America Bold";
      font-size: 24px;
      cursor: pointer;
    }
  }
  .bodyContainer {
    margin-top: 24px;
    display: flex;
  }
  .avatarContainer {
    width: 206px;
    height: 260px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profilePicFieldContainer {
    margin: 64px 0px;
  }

  .profileImageContainer {
    .profileImage {
      height: 250px;
      width: 250px;
      object-fit: cover;
      border-radius: 125px;
    }
  }

  .saveProfileButtonsContainer {
    align-items: center;
    .saveChangesButton {
      background-color: #311e86;
      color: #ffffff;
      border: none;
      padding: 12px 24px;
      border-radius: 5px;
      margin-right: 24px;
      font-family: "GT America Bold";
    }
    .cancelChangesButton {
      background-color: #ffffff;
      color: #311e86;
      border: 1px solid #311e86;
      padding: 12px 24px;
      border-radius: 5px;
      font-family: "GT America Bold";
    }
  }

  .primaryButtonStyles {
    background-color: #311e86;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    font-family: "GT America Bold";
  }

  .defaultAvatarContainer {
    background-color: #311e86;
    width: 166px;
    height: 166px;
    border-radius: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    margin-top: 20px !important;
    margin-bottom: 24px !important;
  }

  .editAvatarButton {
    background-color: #bdb9ff;
    color: #311e86;
    border: none;
    padding: 7px 25px;
    font-family: "GT America Bold";
    font-size: 14px;
    border-radius: 5px;
  }
  .previewImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    .previewImage {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50px;
      margin: 16px auto;
    }

    input {
      margin: 16px 0px;
      width: 100%;
    }
  }

  .avatarImageContainer {
    width: 166px;
    height: 166px;
    border-radius: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    margin-top: 20px !important;
    margin-bottom: 24px !important;
    .avatarImage {
      width: 166px;
      height: 166px;
      border-radius: 83px;
    }
  }

  .contentContainer {
    margin-left: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
    border-radius: 16px;
    display: flex;
    flex: 1;

    .contentInnerContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 34px;
    }
  }

  .fieldContainer {
    .fieldLabel {
      font-size: 14px;
      font-family: "GT America Regular";
      color: #19233c;
      margin-bottom: 14px;
    }
    .fieldValue {
      font-size: 24px;
      font-family: "GT America Bold";
      color: #19233c;
      margin-bottom: 24px;
    }
  }
  .customFileUpload {
    width: 90%;
    height: 200px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "GT America Regular";
    padding: 6px 12px;
    border-radius: 16px;
    margin: 10px;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }

  .textInputStyles {
    width: 100%;
    min-width: 400px;
    border: 0.5px solid #707d9b;
    height: 36px;
    font-size: 24px;
    font-family: "GT America Bold";
    border-radius: 5px;
    margin-bottom: 12px;
  }

  .textInputStyles::placeholder {
    font-size: 24px;
    font-family: "GT America Bold";
    color: #707d9b;
  }
}

.referralBannerContainer {
  background-color: #bebbef;
  padding: 30px 34px;
  .referralBannerTitle {
    font-family: "GT America Bold";
    font-size: 24px;
    color: #311e86;
    margin-bottom: 8px;
  }
}
