.waitingRoomPageContainer {
  background-color: #a5d9df;
  width: 100%;
  height: 100% !important;
  // height: calc(100% - 80px);
  height: 750px !important;
  position: relative;
  .waitingRoomTextContainer {
    position: absolute;
    top: 88px;
    left: 0;
    right: 0;
    margin: 0 auto;
    .waitingRoomTitle {
      text-align: center;
      font-family: "GT America Bold";
      color: #19233c;
      size: 64px;
      // margin-top: 88px;
    }

    .waitingRoomSubtitle {
      text-align: center;
      font-family: "GT America Bold";
      color: #19233c;
      size: 36px;
    }
  }
  .canvasContainer {
    height: 100%;
    width: 100%;
  }
}

#canvas-container {
  height: 500px;
  width: 500px;
}

.threeCanvas {
  height: 100%;
}

.waitingRoomContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  background: #a4d9df;
  align-items: center;
  color: #19233c;
  .waitingRoomTitle {
    font-family: "GT America Medium";
    font-size: 64px;
    font-weight: bold;
    line-height: 38px;
    margin-top: 80px;
  }
  .waitingRoomSub {
    font-family: "GT America Regular";
    font-size: 36px;

    line-height: 30px;

    margin-top: 36px;
  }
  .waitingRoomImg {
    height: 934px;
  }
}
