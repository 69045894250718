.expandable-card-container {
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 466px;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  padding: 20px 20px;
  transition: all 300ms ease-in-out;
  position: relative;
  cursor: pointer;
  position: relative;
  .card-banner {
    position: absolute;
    background-color: lightblue;
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    span {
      display: block;
      transform: rotate(-45deg);
    }
    ::after,
    ::before {
      border-top-color: transparent;
      border-left-color: transparent;
    }
  }
  .card-heading-container {
    text-align: center;
  }
  .card-heading {
    font-family: "GT America Bold";
    line-height: 20px;
    margin-bottom: 15px;
    font-size: 24px;
    transition: all 300ms ease-in-out;
  }
  .card-img-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 16px;
    transition: all 300ms ease-in-out;
    img {
      height: auto;
      width: 100%;
    }
  }
  .card-details-container {
    display: block;
    font-family: "GT America Regular";
    font-size: 14px;
    margin-top: 24px;

    .card-pointers {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px;
      font-weight: bold;
      color: #19233c;
      img {
        margin-right: 14px;
      }
    }
    .card-desc {
      margin-top: 24px;
    }
  }
  transform: translateY(-50px);
  &:hover {
    box-shadow: 0px 0px 30px #aabfc1;
  }
  .comming-soon-text {
    text-align: center;
    font-family: "GT America Medium";
    font-size: 24px;
    // font-weight: bold;
    // margin-top: 15px;
    color: grey;
  }
}

.ribbon-wrapper {
  position: absolute;
  width: 126.49px;
  height: 112.82px;
  position: absolute;
  top: 0px;
  right: 0px;
  .ribbon {
    text-align: center;
    font-family: "GT America Bold";
    font-size: 14px;
    line-height: 20px;
    color: #bdb9ff;
    text-align: center;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    top: 23px;
    right: -10px;
    width: 159.21px;
    background-color: #311e86;
  }
}

.card-sm {
  width: 325px;
  height: 406px;
  .card-heading {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .card-img-container {
    height: 331px;
  }
  transform: none;
  &:hover {
    transform: none;
  }
}

.pu-stat-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  font-family: "GT America Regular";

  font-size: 14px;
  line-height: 19px;
  .stat-card-label {
    margin: 0px;
  }
  .stat-card-value {
    margin: 0px;
    font-family: "GT America Bold";
    font-size: 14px;
  }
}
