.StepperContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .StepperItem {
    display: flex;
    align-items: center;
    // p {
    //   margin: 0;
    //   text-transform: capitalize;
    //   margin-left: 4px;
    //   font-size: 14px;
    //   font-family: "GT America Bold";
    // }
  }

  .completed {
    height: 24px;
    width: 24px;
    font-size: 24px;
    margin: 0px;
    color: #311e86;
  }

  .upcoming {
    border: 4px solid #bdb9ff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  .loader {
    border: 4px solid #bdb9ff;
    border-top: 4px solid #311e86;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
  }

  .StepperTitle {
    font-family: "GT America Bold";
    font-size: 18px;
    margin: 4px 0px 4px 8px;
  }
  .StepperLoadingStateContainer {
    margin-left: 30px;
    .StepperLoadingStateTitle {
      font-family: "GT America Bold";
      font-size: 16px;
      margin-bottom: 0px;
    }
    .StepperLoadingStateDescription {
      font-family: "GT America Regular";
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
}
