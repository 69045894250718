.blockbotCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-top: 20px;
  gap: 25px;
  // height: 88vh;
  margin-bottom: 20px;
  overflow-y: auto;
  padding-block: 15px;
  // scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;
  grid-template-rows: max-content;
}
// .blockbotCardsContainer::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
//   border-radius: 10px;
//   background-color: red !important;
// }

// .blockbotCardsContainer::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }

.whiteShade {
  background-color: #f6f9ff;
}
// .blockbotCardsContainer > * {
//   flex: 0 0 33.3333%;
// }

.nextButton {
  margin-top: 64px;
  margin-bottom: 128px;
  height: 58px;
  width: 185px;
  background-color: #311e86;
  color: #ffffff;
  font-size: 24px;
  font-family: "GT America Bold";
  border: none;
  border-radius: 5px;
}

.wizardNavigationContainer {
  margin: 77px 0px 117px 0px;
  display: flex;
  justify-content: center;
  .secondaryButton {
    // padding: 20px 65px;
    font-family: "GT America Regular";
    font-weight: bold;
    font-size: 24px;
    border: 1px solid #311e86;
    border-radius: 5px;
    background-color: transparent;
    color: #311e86;
    margin-right: 64px;
    width: 185px;
    // height: 58px;
  }
  .primaryButton {
    padding: 10px 0;
    font-size: 24px;
    font-family: "GT America Regular";
    font-weight: bold;
    border-radius: 5px;
    background-color: #311e86;
    color: #ffffff;
    width: 185px;
    // height: 58px;
  }
  .primaryButton:disabled,
  .primaryButton[disabled] {
    background-color: #423775;
    color: #ffffff;
    cursor: not-allowed;
  }
}

.collapsibleCard {
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px #cdd8ed8a;
  min-height: 430px;
  .cardContent {
    display: flex;
    // align-items: center;
    flex-direction: column;
  }
  .cardImg {
    height: 251px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #7ebaff;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cardInfoWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px;
  }

  .cardInfo {
    display: flex;
    flex-direction: column;
    color: #19233c;
    flex-grow: 1;
  }
  .cardTitle {
    font-family: "GT America Medium";
    font-size: 20px;
    line-height: 30px;
    // font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 99%;
  }
  .cardDesc {
    font-family: "GT America Regular";
    font-size: 14px;
    line-height: 19px;
    // margin-top: 16px;
  }
  .chevCont {
    font-size: 30px;
    color: #19233c;
    cursor: pointer;
  }
  .collapseContainer {
    margin-top: 20px;
    .greyTitle {
      font-family: "GT America Medium";
      color: #707d9b;
      font-size: 14px;
    }
  }
  .attrCont {
    display: flex;
    // flex-wrap: wrap;
    column-gap: 5px;
    justify-content: space-between;
    padding: 0 15px 15px;
  }
}

.blueBorder {
  border: 2px solid #311e86;
}

// .openCard {
//   grid-row: span 1.5;
// }
.blockbotCardsContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
  margin-block: 15px;
  // background-color: red !important;
}
.blockbotCardsContainer::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.blockbotCardsContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  // background-color: #d62929;
}

.pagination {
  display: flex;
  list-style: none;

  .li:active,
  a:active {
    background-color: #311e86;
  }
  .page-item.active .page-link {
    background-color: #423775;
  }
}

.pageLink:active {
  background-color: #423775;
}
.previousPageItem {
  display: none !important;
}
.nextPageItem {
  display: none !important;
}

.pageLink {
  height: 53px;
  width: 53px;
  border: 1px solid #423775;
  margin: 8px;
  padding: 10px 16px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #423775;
}

.pageLink:hover {
  a {
    background-color: #423775;
    color: #ffffff;
  }
}

.activePageLink {
  a {
    border: 1px solid #423775;
    background-color: #423775;
    color: #ffffff;
  }

  a:hover {
    color: #ffffff;
  }
}
