@mixin buttonStyles() {
  border: none;
  background-color: #311e86;
  border-radius: 5px;
  padding: 17px 71px;
  color: #ffffff;
  font-family: "GT America Bold";
  font-size: 16px;
}

.walletPageContainer {
  background-color: #bdb9ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("https://blockbots-s3.s3.ap-southeast-1.amazonaws.com/BG_Game_Screen.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 100vh;
  width: 100%;
  // background-origin: border-box;
}

.walletContentContainer {
  background-color: #ffffff;
  margin-top: 35px;
  margin-left: 34px;
  margin-right: 34px;
  border-radius: 5px;
}
.walletsContainers {
  display: flex;
  margin: 32px 24px;

  .walletContainer::-webkit-scrollbar {
    width: 4px;
  }

  .walletContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .walletContainer::-webkit-scrollbar-thumb {
    background-color: #ced7e9;
    outline: 1px solid #aeb8d0;
  }

  .yourWalletContainer {
    width: calc((100% - 24px) / 2);
    height: calc((100vh - 85px - 300px));
    border: 2px solid #f6f9ff;
    margin-right: 24px;
    overflow-y: scroll;
  }

  .spenderWalletContainer {
    width: calc((100% - 24px) / 2);
    height: calc((100vh - 85px - 300px));
    border: 2px solid #f6f9ff;
    overflow-y: scroll;
  }

  .spenderWalletBalanceContainer {
    padding-bottom: 30px !important;
  }

  .indBalanceContainer {
    background-color: #f6f9ff;
    margin: 24px;
    padding: 24px;
    border-radius: 5px;
    .indBalanceLabelsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   padding: 24px;
      .indBalanceLabel {
        font-size: 24px;
        font-family: "GT America Bold";
        color: #19233c;
      }
      .indBalanceAccount {
        font-family: "GT America Regular";
        font-size: 14px;
      }
    }
    .indBalanceValueContainer {
      background-color: #ffffff;
      padding: 10px 12px;

      .indBalanceValue {
        margin-bottom: 0px;
        font-size: 24px;
        font-family: "GT America Bold";
      }
    }
  }
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 32px;

  .primaryButton {
    @include buttonStyles();
  }
}

.depositModalHeaderText {
  font-family: "GT America Bold";
  font-size: 24px;
  text-align: center;
}
.chooseDescriptionText {
  font-family: "GT America Regular";
  font-size: 18px;
  margin: 18px auto;
  text-align: center;
}

.depositDropdown {
  display: flex;
  justify-content: center;
  outline: none;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    background-color: none;
  }
  &:active {
    background-color: #fff;
    color: black;
  }
  .depositDropdownToggle {
    background-color: #ffffff;
    border: 1px solid #311e86;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 10px;
    padding: 10px;

    &:focus {
      box-shadow: none;
      background-color: none;
    }

    &:active {
      background-color: #fff !important;
      box-shadow: none !important;
      color: black;
    }

    .dropdownSelectorText {
      color: #311e86;
      margin: 0;
      font-family: "GT America Bold";
      font-size: 16px;
    }
  }

  .dropDownMenu {
    width: 80%;
    margin-top: 10px;
  }
}

.powerupsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 24px;
}

// Modal related styles
.ModalContainer {
  // width: 40%;
  margin-top: 70px;
  position: relative;
}

.ModalHeader {
  align-self: center;
  border-bottom: 1px solid #e7eaf1;
  width: 300px;
  display: flex;
  align-items: center;
  padding-top: 35px;
  p {
    margin-bottom: 0px;
  }
}

.ModalBody {
  min-height: 35vh;
  max-height: 90vh;
  border: none;
  overflow-y: scroll;
  padding-bottom: 45px;
}

.ModalBody::-webkit-scrollbar {
  width: 4px;
}

.ModalBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ModalBody::-webkit-scrollbar-thumb {
  background-color: #ced7e9;
  outline: 1px solid #aeb8d0;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  .inputStyles {
    width: 80%;
    padding: 5px 14px;
  }

  .customButton {
    @include buttonStyles();
    padding: 10px 0px;
    margin-top: 24px;
    padding: 7px 25px;
  }
}

.StepperModal {
  width: 70%;
  margin: 0 auto;
  padding: 18px 0;
}

.OvalLoadingContainer {
  width: 40%;
  margin: 0 auto;
}

.emptySpenderWalletPowerupsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .emptySpenderWallerPowerupsImage {
    height: 250px;
    width: 250px;
  }
  .emptySpenderWalletPowerupsText {
    font-family: "GT America Bold";
    text-align: center;
    margin-top: 14px;
    margin-bottom: 0px;
  }
}

.getTokensContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;

  .getTokensButton {
    text-decoration: none;
    p {
      padding: 7px 24px;
      margin-top: 14px;
      margin-right: 14px;
      margin-bottom: 0px;
      border-radius: 5px;
      font-family: "GT America Bold";
      font-size: 14px;
      background-color: #bdb9ff;
      color: #311e86;
    }
  }

  .getTokensButton:hover {
    p {
      background-color: #4d4eb8;
      color: #ffffff;
    }
  }
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.closeButtonContainer {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  // padding: 15px;
}

.indTokenSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin-top: 14px;
  .IconButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #311e86;
    padding: 7px 10px;
    min-width: 90px;
    border-radius: 5px;
    &:hover {
      background: #bdb9ff;
      cursor: pointer;
      border: 1px solid #bdb9ff;
    }
    p {
      margin: 0;
      margin-left: 8px;
      font-family: "GT America Bold";
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #311e86;
    }
  }
  .ActiveIconButton {
    background: #bdb9ff;
    border: 1px solid #bdb9ff;
  }
}

.DropdownItem {
  margin: 5px 0;
}

.MigrateAssetText {
  text-align: center;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: -15px;
  width: 90%;
}

.MigrationNotification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(189, 185, 255, 0.5);
  border-radius: 6px;
  margin-top: 30px;
  div {
    display: flex;
    align-items: center;
    p {
      margin-top: 15px;
      margin-left: 18px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      color: #311e86;
    }
  }
  .CrossIcon {
    &:hover {
      cursor: pointer;
    }
  }
}
