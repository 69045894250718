.selectPowerupDetail {
  height: 385px;
  // width: 253.75px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgb(205 216 237 / 54%);
  border-radius: 16px;
  padding: 10px 9px;
  .imgPowerupDetailCard {
    height: 185px;
    // width: 213.75px;
    object-fit: contain;
    border-radius: 16px;
  }
}

.statsContainer {
  margin-top: 10px;
  margin-bottom: 13px;
  min-height: 90px;
  max-height: 90px;
  overflow-y: auto;
  background: #e7eefdce;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.powerupBlankCard {
  border: 2px dashed #abc2f0;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 15px rgba(205, 216, 237, 0.5));
  border-radius: 16px;
  background-color: transparent;
  height: 381px;
  // width: 208px;
}

.removePowerupDetail {
  // width: 208px;
  height: 381px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  .imgPowerupDetailCard {
    height: 156px;
    width: 168px;
    border-radius: 16px;
    object-fit: contain;
  }
}

.disabledCard {
  opacity: 0.8;
}

.disabledButton {
  pointer-events: none;
  cursor: not-allowed;
}

.selectedBorder {
  border: 2px solid #311e86;
}

.powerupTypeTextContainer {
  position: absolute;
  bottom: 5px;
  .powerupTypeText {
    width: 80px;
    border-radius: 5px;
    padding: 4px;
    background-color: #ffffff;
    color: #311e86;
    font-size: 10px;
    line-height: 19px;
    font-family: "GT America Bold";
    margin: 0px;
    text-transform: uppercase;
    text-align: center;
  }
}

.powerupCardTitle {
  font-family: "GT America Bold";
  margin-bottom: 8px;
}

.powerupImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.hardDisable {
  // pointer-events: none;
  opacity: 0.8;
}
