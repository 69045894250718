.dropboardContainer {
  margin-top: 15px;
}

.dropboard {
  background-color: #eaeffa;
  // min-height: 481px;
  border-radius: 16px;
  padding: 30px 0;
  .dropboardTitle {
    font-size: 24px;
    font-family: "GT America Bold";
    text-align: center;
    margin-bottom: 30px;
  }
}

.actionTitle {
  font-size: 24px;
  font-family: "GT America Bold";
  text-align: center;
  margin-bottom: 25px;
}

.selectedActionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.actionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.actionBlankCard {
  border: 2px dashed #abc2f0;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 15px rgba(205, 216, 237, 0.5));
  border-radius: 16px;
  background-color: transparent;
  width: 368px;
  height: 94px;
}

.actionsWrapper {
  margin: 25px 0;
}

.rulesTitle {
  margin: 0px;
  padding: 0px;
}
.rulesList {
  margin: 0px;
  padding: 14px;

  .rulesListItem {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    text-align: left;
  }
}
