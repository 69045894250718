.selectedBlockbotAction {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  top: 700px;
  left: 10%;
  width: 285px;
  background-color: #ffffff;
  border-radius: 16px;
  align-items: center;
  padding: 15px;
}

.opponentBlockbotAction {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  top: 700px;
  right: 10%;
  width: 285px;
  background-color: #ffffff;
  border-radius: 16px;
  align-items: center;
  padding: 15px;
}
.blockbotActionLabel {
  font-size: 18px;
  font-family: "GT America Bold";
  margin: 0px;
}

.blockbotActionLabelContainer {
  background-color: #311e86;
  display: flex;
  width: 90%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  .blockbotActionIcon {
    height: 35px;
    width: 55px;
    object-fit: contain;
    margin: 10px 0px;
  }

  .blockbotActionValue {
    font-size: 18px;
    font-family: "GT America Bold";
    color: #ffffff;
    margin: 0px;
  }
}
