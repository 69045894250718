.purplePrimary {
  color: #fff;
  background-color: #311e86;
  border-color: #311e86;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none !important;
  &:hover {
    background-color: #bdb9ff;
    border-color: #bdb9ff !important;
    outline: none !important;
    color: #311e86 !important;
  }
}

.purplePrimary:focus,
.purplePrimary:active .purplePrimary:hover {
  outline: none !important;
  box-shadow: none;
  background-color: #311e86;
  border-color: #311e86;
  color: #fff !important;
}
.purplePrimary:not(:disabled):not(.disabled):active,
.purplePrimary:focus {
  color: #311e86;
}

.purplePrimary:active {
  background-color: #311e86;
  border-color: #311e86;
  color: #fff;
  outline: #311e86;
}
