.selectedBlockbotTitle {
  margin-top: 64px;
  margin-bottom: 48px;
  font-family: "GT America Bold";
  font-size: 24px;
  line-height: 30px;
}

.actionContainer {
  display: flex;
  column-gap: 20px;
  margin-top: 25px;
}

.wizardNavigationContainer {
  margin: 77px 0px 117px 0px;
  display: flex;
  justify-content: center;
  .secondaryButton {
    padding: 20px 65px;
    font-family: "GT America Bold";
    font-size: 24px;
    border: 1px solid #311e86;
    border-radius: 5px;
    background-color: transparent;
    color: #311e86;
    margin-right: 64px;
  }
  .primaryButton {
    padding: 20px 65px;
    font-family: "GT America Bold";
    font-size: 24px;
    border-radius: 5px;
    background-color: #311e86;
    color: #ffffff;
  }
  .primaryButton:disabled,
  .primaryButton[disabled] {
    background-color: #423775;
    color: #ffffff;
    cursor: not-allowed;
  }
}

.navBtnCont {
  display: flex;
  gap: 15px;
}

.RoundButtonContainer {
  display: flex;
  justify-content: space-between;
  min-width: 130px;
  align-items: center;

  .VerticalDiv {
    width: 24.97px;
    height: 0px;
    border: 1px solid #abc2f0;
    transform: rotate(90deg);
    margin-top: 1px;
  }
}

.LoadoutButtonsContainer {
  display: flex;
  justify-content: space-between;
}
